/* You can add global styles to this file, and also import other style files */

$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";

@import "../node_modules/css-file-icons/build/css-file-icons.css";

.buttonMain {
    height: 40px; 
    width: 40px; 
    margin-right: 5px;
}
.buttonRow, .p-button-success {
    height: 27px; 
    width: 27px; 
    margin-right: 5px;
}
.buttonMargin {
    margin-right: 5px;
}

.fieldMandatory {
    color: red;
}
  
.fieldDisable {
    color: gray;
}